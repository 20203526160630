<script lang="ts" setup>
import { getUserDevice } from '~/common/Common'
import { DEVICE_TYPE } from '~/enum/enum'

const app = useAppStore()
const type = getUserDevice(app.appType)
const emit = defineEmits(['close'])
</script>

<template>
  <!-- 앱 접근 권한을 안내 -->
  <div class="modalWrap">
    <div class="modalMask"></div>
    <div class="modalInnerWrap">
      <div class="modalContents appRightsModal">
        <header>
          <h2>앱 접근 권한을 안내해 드려요</h2>
          <p>
            Window는 정보통신망법 준수 및 서비스 제공을 위해 서비스에 꼭 필요한
            기능에 접근하고 있습니다.
          </p>
        </header>
        <main>
          <h3>선택 권한</h3>
          <dl
            v-if="
              type === DEVICE_TYPE.IOS_M_APP || type === DEVICE_TYPE.IOS_T_APP
            "
            class="appRightsPhoto"
          >
            <dt>사진 보관함</dt>
            <dd>프로필 변경, 숙제 제출 시 사진 첨부</dd>
          </dl>
          <dl
            v-if="
              type === DEVICE_TYPE.IOS_M_APP || type === DEVICE_TYPE.IOS_T_APP
            "
            class="appRightsMike"
          >
            <dt>마이크</dt>
            <dd>숙제 제출 시 녹음 진행</dd>
          </dl>
          <dl
            v-if="
              type === DEVICE_TYPE.IOS_M_APP || type === DEVICE_TYPE.IOS_T_APP
            "
            class="appRightsCamera"
          >
            <dt>카메라</dt>
            <dd>프로필 변경, 숙제 제출 시 사진 촬영</dd>
          </dl>
          <dl
            v-if="
              type === DEVICE_TYPE.IOS_M_APP || type === DEVICE_TYPE.IOS_T_APP
            "
            class="appRightsAlarm"
          >
            <dt>알림</dt>
            <dd>학습 과제 및 셔틀 버스 등 중요 알림 수신</dd>
          </dl>

          <dl
            v-if="
              type === DEVICE_TYPE.ANDROID_M_APP ||
              type === DEVICE_TYPE.ANDROID_T_APP
            "
            class="appRightsMike"
          >
            <dt>마이크</dt>
            <dd>숙제 제출 시 녹음 진행</dd>
          </dl>
          <dl
            v-if="
              type === DEVICE_TYPE.ANDROID_M_APP ||
              type === DEVICE_TYPE.ANDROID_T_APP
            "
            class="appRightsPhoto"
          >
            <dt>사진 및 동영상</dt>
            <dd>프로필 변경, 숙제 저장 및 제출 시 사진 첨부</dd>
          </dl>
          <dl
            v-if="
              type === DEVICE_TYPE.ANDROID_M_APP ||
              type === DEVICE_TYPE.ANDROID_T_APP
            "
            class="appRightsAlarm"
          >
            <dt>알림</dt>
            <dd>학습 과제 및 셔틀 버스 등 중요 알림 수신</dd>
          </dl>
          <dl
            v-if="
              type === DEVICE_TYPE.ANDROID_M_APP ||
              type === DEVICE_TYPE.ANDROID_T_APP
            "
            class="appRightsAudio"
          >
            <dt>음악 및 오디오</dt>
            <dd>숙제 제출 시 녹음 파일 첨부</dd>
          </dl>
          <dl
            v-if="
              type === DEVICE_TYPE.ANDROID_M_APP ||
              type === DEVICE_TYPE.ANDROID_T_APP
            "
            class="appRightsCamera"
          >
            <dt>카메라</dt>
            <dd>프로필 변경, 숙제 제출 시 사진 촬영</dd>
          </dl>
        </main>
        <div class="btnStyleArea mt">
          <a class="bgActive big cursor-pointer" @click="emit('close')">확인</a>
        </div>
      </div>
    </div>
  </div>
  <!-- //앱 접근 권한을 안내 -->
</template>

<style lang="css"></style>

<script lang="ts" setup>
import { setupTermsInfo } from '~/apis/setting'
import { useAppStore } from '~/stores/app'

const emit = defineEmits(['back', 'close'])
const rawHtml = ref('')
const title = ref('')
onMounted(async () => {
  const res = await setupTermsInfo({
    CAMP_CD: useAppStore().userInfo?.CAMP_CD ?? '',
  })
  rawHtml.value = res.data.data.TERMS_OF_USE_HTML
  title.value = res.data.data.BRAND_NM
})
</script>

<template>
  <div v-if="rawHtml != ''" class="privacyLayer">
    <section>
      <header>
        <a class="btnLayerBack" @click="emit('back')">레이어 클로즈</a>
        <h2>이용약관</h2>
        <a class="btnLayerClose" @click="emit('close')">레이어 클로즈</a>
      </header>
      <main>
        <div class="cont privacyWrap">
          <h3 class="tit">{{ title }} 이용약관</h3>
          <div v-dompurify-html="rawHtml" class="privacy scroll"></div>
        </div>
      </main>
    </section>
  </div>
</template>

<style lang="css" scoped></style>

<script lang="ts" setup>
import { setupPrivacyInfo } from '~/apis/setting'
import { useAppStore } from '~/stores/app'

const emit = defineEmits(['back', 'close'])
const rawHtml = ref('')
const title = ref('')
const second_type = ref('Y')
const cctvInfo = ref('')

onMounted(async () => {
  const res = await setupPrivacyInfo({
    CAMP_CD: useAppStore().userInfo?.CAMP_CD ?? '',
  })
  second_type.value = res.data.data.SECOND_TYPE
  rawHtml.value = res.data.data.PRIVACY_POLICY_HTML
  if (second_type.value === 'N') {
    cctvInfo.value = res.data.data.PORTRAIT_RIGHTS_HTML
  }
  title.value = res.data.data.BRAND_NM
})
</script>

<template>
  <div v-if="rawHtml != ''" class="privacyLayer">
    <section>
      <header>
        <a class="btnLayerBack" @click="emit('back')">레이어 클로즈</a>
        <h2>개인정보 처리방침</h2>
        <a class="btnLayerClose" @click="emit('close')">레이어 클로즈</a>
      </header>
      <main>
        <div class="cont privacyWrap">
          <h3 class="tit">{{ title }} 개인정보 처리 방침</h3>
          <div class="privacy scroll privacyNum">
            <div v-dompurify-html="rawHtml"></div>
          </div>
          <h3 v-if="second_type === 'Y'" class="tit">
            CCTV 영상 정보 수집 동의
          </h3>
          <h3 v-else class="tit">초상권 활용에 대한 안내</h3>
          <div v-if="second_type === 'Y'" class="privacy scroll">
            <h3>1.영상(얼굴)정보의 수집 및 이용목적</h3>
            <p>
              Edu Brain Co. Ltd이 회원님의 영상(얼굴) 정보를 수집하는 목적은
              학생 및 시설의 안전과 도난 및 범죄예방을 위한 것입니다.
            </p>
            <h3>2. 개인정보의 보유 및 이용기간</h3>
            <p>
              Edu Brain Co. Ltd은 개인정보 보유 및 이용기간은 2주 후 삭제를
              원칙으로 하며, 2주 이내 삭제를 요청할 경우 해당 정보를 삭제하실 수
              있습니다. 제공된 영상 정보는 회원님의 동의 없이 관련된 목적 외의
              다른 어떠한 용도로도 사용되지 않습니다.
            </p>
            <h3>
              3. 개인정보 제공동의 거부권리 및 거부에 따른 불이익 내용 또는
              제한사항
            </h3>
            <p>
              회원님은 영상정보 제공에 동의하지 않을 권리가 있습니다. 다만,
              학원에서 제공받는 정보가 제한 될 수 있으며 개인 정보 제공에
              동의하지 않을 경우 반 편성이 제한될 수 있습니다.
            </p>
            <p>
              <strong
                >* 만 14세 미만 아동인 경우 반드시 학부모 등 법적 대리인의
                동의가 필요 함 본인은 Edu Brain Co. Ltd 재원생인 미성년자 자녀의
                법정 대리인으로 위 내용을 위해 영상 정보를 수집하는데
                동의합니다.
              </strong>
            </p>
          </div>
          <div
            v-if="second_type === 'N'"
            v-dompurify-html="cctvInfo"
            class="privacy scroll"
          />
        </div>
      </main>
    </section>
  </div>
</template>

<style lang="css" scoped></style>
